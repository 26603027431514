import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from 'src/components/Layout'
import SEO from 'src/components/seo'
import chevron from 'src/images/prumdrt2020/chevron_up.svg'
import SectionTitle from 'src/components/SectionTitle'

import 'src/styles/home.scss'
import 'src/styles/leaderboard.scss'

import useWindowSize from 'src/hooks/useWindowSize'

import badge_1st from 'src/images/prumdrt2020/badge/badge_1st.png'
import badge_2nd from 'src/images/prumdrt2020/badge/badge_2nd.png'
import badge_3rd from 'src/images/prumdrt2020/badge/badge_3rd.png'

import { Bar, defaults } from 'react-chartjs-2'

defaults.global.defaultFontFamily = 'FSAlbertPro'
export default ({ data }) => {
  const leaderboard_content =
    data.allRestApiApiLeaderboard.edges[0].node.content

  const locale = data.allRestApiApiLeaderboard.edges[0].node.locale
  const size = useWindowSize()

  const [currentIndex, setCurrentIndex] = useState(0)

  const tableRef = useRef(null)

  const BADGE = [badge_1st, badge_2nd, badge_3rd]

  const [leaderboardData, setLeaderboardData] = useState({
    top_20: [
      { name: 'Chengrong Zhong', country: 'Hong Kong', rank: 4 },
      { name: 'Chi Shing Kenneth Luk', country: 'Hong Kong', rank: 5 },
      { name: 'Chin Kwan Yap', country: 'Malaysia', rank: 6 },
      { name: 'Chong Wai Louis Chan', country: 'Hong Kong', rank: 7 },
      { name: 'Hung Lang Siu', country: 'Hong Kong', rank: 8 },
      { name: 'Ka Lun Moses Tse', country: 'Hong Kong', rank: 9 },
      { name: 'Kin Sang Catherine Poon', country: 'Hong Kong', rank: 10 },
      { name: 'Lilian Sutanto', country: 'Indonesia', rank: 11 },
      { name: 'Ling Katrina Zhao', country: 'Hong Kong', rank: 12 },
      { name: 'Penghui Ding', country: 'Hong Kong', rank: 13 },
      { name: 'Wan Chiun Huang', country: 'Malaysia', rank: 14 },
      { name: 'Xiaomin Annie Rong', country: 'Hong Kong', rank: 15 },
      { name: 'Xin Feng Cherry Zhang', country: 'Hong Kong', rank: 16 },
      { name: 'Xiuzhen Mini Zhang', country: 'Hong Kong', rank: 17 },
      { name: 'Yan Lani Liu', country: 'Hong Kong', rank: 18 },
      { name: 'Yik Ling Chau', country: 'Hong Kong', rank: 19 },
      { name: 'Zheng Michael Liu', country: 'Hong Kong', rank: 20 }
    ],
    top_3: [
      { name: 'Yik Yee Yap', country: 'Malaysia', rank: 1 },
      { name: 'Ruirui Davis Diao', country: 'Hong Kong', rank: 2 },
      { name: 'Teng Carson Li', country: 'Hong Kong', rank: 3 }
    ],
    my_record: {},
    country_leaderboard: [
      { name: 'Africa', legend: 'AF', total_score: 45 },
      { name: 'Cambodia', legend: 'CM', total_score: 605 },
      { name: 'China', legend: 'CN', total_score: 949 },
      { name: 'Hong Kong', legend: 'HK', total_score: 141616 },
      { name: 'Indonesia', legend: 'ID', total_score: 4382 },
      { name: 'Malaysia', legend: 'MY', total_score: 26965 },
      { name: 'Philippines', legend: 'PH', total_score: 3115 },
      { name: 'Singapore', legend: 'SG', total_score: 9461 },
      { name: 'Thailand', legend: 'TH', total_score: 60 },
      { name: 'Vietnam', legend: 'VN', total_score: 7264 }
    ],
    legends: [
      'AF = Africa',
      'CM = Cambodia',
      'CN = China',
      'HK = Hong Kong',
      'ID = Indonesia',
      'MY = Malaysia',
      'PH = Philippines',
      'SG = Singapore',
      'TH = Thailand',
      'VN = Vietnam'
    ]
  })

  const scrollTableTo = direction => {
    if (direction === 'right') {
      tableRef.current.scroll({
        top: 0,
        left: (currentIndex + 1) * (size.width - 60),
        behavior: 'smooth'
      })
      setCurrentIndex(currentIndex + 1)
    } else if (direction === 'left') {
      tableRef.current.scroll({
        top: 0,
        left: (currentIndex - 1) * (size.width - 60),
        behavior: 'smooth'
      })
      setCurrentIndex(currentIndex - 1)
    }
  }
  //
  const renderLeaderboards = () => (
    <div className="leaderboards-section ">
      <div className="leaderboard-title-wrapper ">
        <SectionTitle
          hasIcon={false}
          title={leaderboard_content.leaderboard}
        ></SectionTitle>
      </div>
      <div className="top-3-boards">
        {leaderboardData.top_3.map((item, index) => (
          <div className="result-panel">
            <div className="badge">
              <img src={BADGE[index]} alt="" />
            </div>
            <div className="overview-result">
              <p className="overview-result-full-name">{item.name}</p>
              <p>({item.country})</p>
            </div>
          </div>
        ))}
      </div>
      <div></div>
      <div className="leaderboard-item-row-data">
        {leaderboardData.top_20.map((item, index) => (
          <div key={index} className="leaderboard-data-item">
            <div className="leaderboard-data-item-name">{item.name}</div>
            <div className="leaderboard-data-item-country">
              ({item.country})
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderLegend = () => (
    <div className="legend-section">
      <div className="legend-container">
        <div className="legend-content">
          {leaderboardData.legends.map(item => (
            <div className="legend-item">{item}</div>
          ))}
        </div>
      </div>
    </div>
  )

  const renderHowTheScoring = () => {
    return (
      <div
        className="how-the-scoring-section"
        style={{
          backgroundImage: `url(${leaderboard_content.scoring_system_background})`
        }}
      >
        <div className="how-the-scoring-content">
          <div className="title-text-box">
            <p>{leaderboard_content.how_the_scoring_system_works}</p>
          </div>
          <ul className="how-scoring">
            {leaderboard_content.how_the_scoring_system_works_content.map(
              item => (
                <li className="how-scoring-text">{parse(item)}</li>
              )
            )}
          </ul>
          <div className="how-scoring-description">
            {leaderboard_content.for_more_information}
          </div>
        </div>
      </div>
    )
  }

  const renderCountryLeaderboardChart = () => {
    let labels = []
    let total_scores = []
    leaderboardData.country_leaderboard.forEach(item => {
      labels.push(item.legend)
      total_scores.push(item.total_score)
    })

    const data = {
      labels: labels,
      datasets: [
        {
          label: leaderboard_content.total_score,
          backgroundColor: 'rgba(14, 70, 127, 1)',
          borderColor: 'rgba(14, 70, 127, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(14, 70, 127, 0.9)',
          hoverBorderColor: 'rgba(14, 70, 127,1)',
          fontSize: 30,
          data: total_scores
        }
      ]
    }
    return (
      <div className="country-leaderboard">
        <SectionTitle
          hasIcon={false}
          title={leaderboard_content.country_leaderboard}
        ></SectionTitle>
        <div className="bar-chart-wrapper">
          <Bar
            data={data}
            width={100}
            height={size.width > 500 ? 500 : 250}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                onClick: () => {
                  return false
                },
                display: false,
                align: 'end',
                labels: {
                  fontSize: size.width > 500 ? 20 : 16,
                  fontFamily: 'FSAlbertPro'
                }
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontSize: size.width > 500 ? 20 : 16,
                      fontFamily: 'FSAlbertPro-Bold',
                      callback: function(value, index, values) {
                        return value
                      }
                    }
                  }
                ]
              }
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <Layout locale={'en'}>
      <SEO title="Leaderboard" />
      <div className="home-banner-wrapper">
        <img src={leaderboard_content.leaderboard_banner} alt="" />
        <div className="blend-line"></div>
      </div>
      <div className="leaderboard-page">
        {renderLeaderboards()}
        {renderCountryLeaderboardChart()}
        {renderLegend()}
      </div>
    </Layout>
  )
}

export const GatsbyQuery = graphql`
  {
    allRestApiApiLeaderboard(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          locale
          content {
            leaderboard
            name
            country
            total_km
            total_time
            virtual_run_score
            virtual_activities_score
            total_score
            legend
            rank
            country_leaderboard
            how_the_scoring_system_works
            for_more_information
            leaderboard_banner
            scoring_system_background
            how_the_scoring_system_works_content
          }
        }
      }
    }
  }
`
